@import '../../assets/scss/variable';

.prysm {
  .integrations {
    max-width: 45%;
  }
  .analyzemonitor {
    max-width: 30%;
    margin-left: 8em;
  }
  .total {
    max-width: 45%;
  }
  .provide {
    padding: 3rem 2rem;
    h3 {
      max-width: 56rem;
      margin: 0 auto 1rem auto;
    }
  }
  .grateful {
    text-align: center;
    padding: 2.93rem 0 4.9rem 0;
    margin: 0 auto;
    h3 {
      margin-bottom: 2.12rem;
    }
    img {
      height: 3rem;
      margin-bottom: 2rem;
    }
  }
  .integrates {
    display: flex;
    width: 75%;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 1rem 6rem 1rem 5rem;
    h3 {
      padding-top: 4rem;
      padding-left: 0.4rem;
      width: 44%;
    }
    img {
      width: 52%;
    }
  }
  .major {
    margin: 6.5rem auto 2.7rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;

    h3 {
      width: 40%;
      padding-right: 6%;
    }
    .majorDescription {
      width: 39%;

      p {
        color: #333333;
      }
    }
  }
  .analyze {
    margin: 10rem auto 3.9rem auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    img {
      width: 52%;
    }
    h3 {
      width: 42%;
    }
  }
  .partners {
    .partnersList {
      li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .partnersDescription {
          padding: 0 10%;
          img {
            max-width: 6.8rem;
            max-height: 4.4375rem;
            object-fit: contain;
            object-position: left;
          }
          h3 {
            margin-bottom: 1.5rem;
          }
          p {
            font-size: 1.9rem;
            color: #7e7670;
            letter-spacing: -0.02rem;
            line-height: 2.4rem;
            margin-bottom: 1.2rem;
          }
          ul {
            font-weight: bold;
            list-style-type: disc;
            font-size: 1.2rem;
            li {
              line-height: 1.5;
            }
          }
          .partnerRate {
            display: flex;
            width: 100%;
            align-items: flex-start;
            justify-content: space-between;
            margin-top: 4rem;
            .partnerRateItem {
              font-weight: bold;
              color: $lightBrown;
              width: 45%;
              div {
                font-size: 4.0625rem;
                letter-spacing: -0.176875rem;
                line-height: 1.875rem;
                margin-bottom: 1rem;
              }
              span {
                font-size: 0.8125rem;
                letter-spacing: -0.014375rem;
                line-height: 1rem;
              }
            }
          }
          .partnerSubList {
            list-style-type: disc;
            padding-left: 1rem;
            li {
              flex-direction: row;
              position: relative;
              &:after {
                content: '';
                position: absolute;
                width: 0.25rem;
                height: 0.25rem;
                background: black;
                top: 50%;
                left: -1rem;
                transform: translateY(-50%);
                border-radius: 50%;
              }
            }
          }
          &.direct {
            padding: 0 3rem 0 9rem;
          }
        }
        &:nth-child(odd) {
          flex-direction: row-reverse;
          .partnerImage {
            width: 46.5%;
          }
          .partnersDescription {
            width: 53.5%;
          }
        }
        &:nth-child(even) {
          .partnerImage {
            width: 53.5%;
          }
          .partnersDescription {
            width: 46.5%;
            padding: 0 2.4% 0 10%;
          }
        }
      }
    }
  }
  .case {
    padding: 0 5.6rem;
    margin: 7rem auto 6.2rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .caseDescription {
      padding: 0;
    }
    img {
      width: 63.6%;
    }
  }
  .amount {
    margin: 4rem 0 4.6rem 0;
    h3 {
      text-align: center;
      font-size: 1.875rem;
      color: #000;
      letter-spacing: 0;
      line-height: 2.5rem;
    }
    .amountInner {
      text-align: center;
    }
  }
}

@media screen and (max-width: 992px) {
  .prysm {
    .partners {
      .partnersList {
        li {
          &:nth-child(odd) {
            flex-direction: row-reverse;
            .partnerImage {
              width: 46.5%;
            }
            .partnersDescription {
              width: 53.5%;
              padding: 0 2%;
            }
          }
          &:nth-child(even) {
            .partnerImage {
              width: 53.5%;
            }
            .partnersDescription {
              width: 46.5%;
              padding: 0 2% 0 2%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .prysm {
    .integrations {
      max-width: 23rem;
    }
    .analyzemonitor {
      max-width: 23rem;
      margin-left: 0;
    }
    .total {
      max-width: 23rem;
    }
    .partners {
      .partnersList {
        li {
          &:nth-child(odd) {
            flex-direction: column;
            .partnerImage {
              width: 100%;
            }
            .partnersDescription {
              padding: 1rem 0;
              width: calc(100% - 4rem);
              p {
                font-size: 0.875rem;
                line-height: 1.46;
                &:nth-last-child(1) {
                  margin-bottom: 0;
                }
              }
            }
          }
          &:nth-child(even) {
            flex-direction: column;
            .partnerImage {
              width: 100%;
              height: 105vw;
              object-fit: cover;
              object-position: top;
            }
            .partnersDescription {
              padding: 1rem 0;
              width: calc(100% - 4rem);
              margin-bottom: 1.2rem;
              p {
                font-size: 0.875rem;
                line-height: 1.46;
              }
            }
          }
          .partnersDescription {
            h3 {
              margin-top: -2.7rem;
              background: $whiteColor;
              padding: 2rem 1.7rem 0.5rem 0.7rem;
              text-align: center;
              margin-bottom: 1.2rem;
              img {
                margin-bottom: 0;
                object-position: center;
              }
            }
            .partnerRate {
              margin-top: 3rem;
              .partnerRateItem {
                div {
                  font-size: 3.625rem;
                  margin-bottom: 0.5rem;
                }
                span {
                  font-size: 0.7rem;
                }
              }
            }
          }
        }
      }
    }
    .integrates {
      width: 100%;
      flex-direction: column;
      padding: 0.4rem 2rem 1rem 2rem;
      h3 {
        width: 100%;
        text-align: center;
        margin-bottom: 5rem;
        padding-top: 0;
      }
      img {
        width: 100%;
      }
    }
    .case {
      padding: 0;
      flex-direction: column;
      margin: 2rem auto 0 auto;
      > div.caseDescription {
        padding: 0 2rem 0 2rem;
        width: 100%;
        margin-bottom: 0;
      }
      img {
        width: 100%;
        padding: 0 2rem;
      }
      h3 {
        padding-bottom: 1.5rem;
      }
    }
    .analyze {
      margin-top: 6rem;
      flex-direction: column;
      margin-bottom: 1.3rem;
      img {
        width: 100%;
        margin-bottom: 2rem;
      }
      h3 {
        width: 100%;
      }
    }
    .major {
      flex-direction: column;
      h3 {
        width: 100%;
        margin-bottom: 1.5rem;
      }
      .majorDescription {
        width: 100%;
      }
    }
    .provide {
      h3 {
        background: white;
        margin-top: -4rem;
        position: relative;
        padding: 1.4rem 0.8rem;
      }
      h4 {
        font-size: 1rem;
        line-height: 1.4375;
      }
    }
    .grateful {
      margin-top: 4.5rem;
      margin-bottom: 0;
      h3 {
        margin-bottom: 2.4rem;
      }
      img {
        height: 3.6rem;
      }
    }
    .amount {
      h3 {
        padding: 0 2rem;
        font-size: 1.125rem;
        letter-spacing: 0.001875rem;
        line-height: 1.625rem;
      }
    }
  }
}
