@font-face {
  font-family: 'Neuzeit Grotesk';
  src: url('../font/NeuzeitGro-Lig.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Neuzeit Grotesk';
  src: url('../font/NeuzeitGro-Bol.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AGaramond Pro';
  src: url('../font/AGaramondPro-Regular.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../font/HelveticaNeueCyr-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
