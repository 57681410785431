@import '../../assets/scss/variable';

@media (any-hover: hover) {
  .prysmTabs {
    .prysmTabList {
      .react-tabs__tab:hover {
        color: $mainColor;
        border-bottom: 0.125rem solid $mainColor;
      }
    }
  }
}

.prysmTabs {
  width: 75%;
  max-width: 75%;
  margin: 0 auto;
  padding: 0.28rem 3.8rem 0.28rem 3.8rem;
  .prysmTabList {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 0.875rem;
    letter-spacing: 0.001875rem;
    line-height: 1.8125rem;
    width: 100%;
    font-weight: normal;
    .react-tabs__tab {
      cursor: pointer;
      color: $lightBrown;
      border-bottom: 0.0625rem solid $lightBrown;
      width: 20%;
      padding: 0.42rem 0.5rem 0.82rem 0;
      line-height: 1.7;
      &.react-tabs__tab--selected {
        font-weight: bold;
        border-bottom: 0.125rem solid $mainColor;
      }
      &:active {
        opacity: 0.8;
        border-bottom: 0.125rem solid $mainColor;
      }
    }
  }
  .react-tabs__tab-panel--selected {
    width: 100%;
  }

  .tabPanel {
    min-height: 20rem;
    padding: 1rem 0 1rem 2.6rem;
    align-items: center;
    justify-content: space-between;
    display: none;
    &.react-tabs__tab-panel--selected {
      display: flex;
    }
    .moveStepThree {
      margin-top: 1em;
    }
    h3 {
      width: 44%;
      font-size: 1.75rem;
    }
    p {
      line-height: 1.3;
      margin-bottom: 0.5rem;
      width: 44%;
    }
    img {
      width: 54%;
    }
  }
  .alignStart {
    align-items: flex-start;
  }
}
