@import '../../assets/scss/variable';

@media (any-hover: hover) {
  .homeTabs {
    .homeTabList {
      .react-tabs__tab:hover {
        color: $mainColor;
      }
    }
  }
}

.homeTabs {
  h5 {
    font-size: 1.25rem;
  }
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  max-width: 100%;
  margin: 0 auto;
  padding: 1.28rem 2.3rem 1.1rem 2.1rem;
  .homeTabList {
    padding-right: 1rem;
    font-size: 0.875rem;
    letter-spacing: 0.001875rem;
    line-height: 1.8125rem;
    width: 13.5rem;
    font-weight: normal;
    .react-tabs__tab {
      cursor: pointer;
      &.react-tabs__tab--selected {
        color: #1577d9;
        font-weight: bold;
      }
      &:active {
        opacity: 0.8;
      }
    }
  }
  .react-tabs__tab-panel--selected {
    width: calc(100% - 14.5rem);
  }
  .tabPanel {
    color: #89817b;
    h5 {
      margin: 0.3rem 0 1.1rem 0;
    }
    p {
      line-height: 1.3;
      margin-bottom: 0.5rem;
    }
    .databaseList {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 48%;
        margin-bottom: 1rem;
        img {
          width: 1.4375rem;
          margin-right: 0.5rem;
        }
        .databaseDescription {
          span {
            display: block;
          }
        }
      }
    }
    button {
      margin-bottom: 0.6rem;
    }
    .vip {
      max-width: 26.1875rem;
      margin-left: 2rem;
    }
    &.second {
      ul {
        list-style-type: disc;
        padding-left: 1.3rem;
        margin-bottom: 1.2rem;
        color: #7e7670;
        li {
          margin-bottom: 1rem;
          font-weight: bold;
        }
      }
      button {
        margin-bottom: 3.4rem;
      }
      h4 {
        text-align: left;
        margin: 0 0 0.6rem 0;
      }
    }
    &.third {
      h5 {
        margin: 0.3rem 0 0.2rem 0;
      }
      p {
        margin-bottom: 0.9rem;
      }
      button {
        margin-bottom: 2.6rem;
      }
      img {
        width: 114%;
      }
    }
    &.four {
      p {
        margin-bottom: 1.2rem;
      }
      button {
        margin-bottom: 2.3rem;
      }
      h5 {
        margin: 0;
      }
    }
    &.five {
      button {
        margin-bottom: 4.2rem;
      }
    }
  }
}
