@import '../../assets/scss/variable';

.how {
  padding: 4.2rem 0;
  h3 {
    font-weight: bold;
    color: $lightBrown;
    letter-spacing: -0.095rem;
    text-align: center;
    line-height: 2.5625rem;
  }
  .howButtons {
    text-align: center;
    button {
      margin: 1.7rem 0.7rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .how {
    background: $whiteColor;
    box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.3);
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0;
    .howButtons {
      button {
        margin: 1rem 0.7rem;
      }
    }
  }
}
