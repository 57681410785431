.slickSlider {
  padding: 0 4.6875rem;
  .sliderItem {
    width: 31.2%;
    height: 100%;
    p {
      text-align: left;
      padding: 0 35px;
      margin-bottom: 0;
      font-size: 1.3rem;
      line-height: 1.46;
    }
  }
  .slick-slide:not(:last-child) {
    border-right: 0.0625rem solid #d8d8d8;
  }
  .slick-dots {
    button:before {
      background: #bebab7;
    }
    .slick-active button:before {
      background: #1577d9;
    }
  }
}

@media screen and (max-width: 768px) {
  .slickSlider {
    padding: 0 2rem;
    .sliderItem {
      width: 100%;
      max-width: 20rem;
      p {
        margin-bottom: 1.8rem;
      }
    }
    .slick-slide:not(:last-child) {
      border-right: none;
    }
  }
}
