@import '../../assets/scss/variable';

.about {
  .stay {
    display: flex;
    flex-wrap: wrap;
    margin: 2.6rem auto 4.8rem auto;
    justify-content: space-between;
    .stayLeft {
      width: 52%;
      padding-left: 5.4%;
      .stayLeftInner {
        padding-right: 2%;
        p:nth-last-child(1) {
          margin-bottom: 0.7rem;
        }
      }
      h3 {
        margin-bottom: 1.7rem;
        letter-spacing: -0.01875rem;
      }
      .stayButton {
        width: 100%;
        margin-top: 1.6rem;
        text-align: left;
      }
    }
    .stayRight {
      width: 35%;
      .halfMonitor {
        width: 100%;
      }
      .monitor {
        display: none;
      }
    }
  }
  .generation {
    margin: 0 auto 2.2rem auto;
    padding: 0 2rem;
    h3 {
      margin-bottom: 2.7rem;
      text-align: center;
    }
    .generationInner {
      max-width: 47rem;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .about {
    .stay {
      flex-direction: column;
      .stayLeft {
        width: 100%;
        padding: 0 2rem;
        margin-bottom: 0;
        .stayLeftInner {
          margin-top: -5.3rem;
          background: $whiteColor;
          position: relative;
          h3 {
            padding: 1rem 0.6rem 0 1rem;
            margin-bottom: 0.7rem;
          }
        }
        button {
          display: none;
        }
      }
      .stayRight {
        width: 100%;
        .halfMonitor {
          display: none;
        }
        .monitor {
          display: block;
          max-height: 82vw;
          object-position: top;
          object-fit: cover;
        }
      }
    }
    .generation {
      margin-bottom: 8rem;
      h3 {
        margin-top: -6.6rem;
        padding: 1rem 0.3rem 0 0.3rem;
        background: $whiteColor;
        position: relative;
        margin-bottom: 1.75rem;
      }
    }
  }
}
