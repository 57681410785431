.prysmSlider {
  padding: 0 0.9rem;
  .sliderItem {
    max-width: 20rem;
    margin: 2rem auto;
    h4 {
      font-size: 1.5rem;
      min-height: 3.6rem;
      line-height: 1;
    }
    ul {
      list-style-type: disc;
      padding-left: 2rem;
      font-size: 1.25rem;
      color: #a3a3a3;
      letter-spacing: 0.001875rem;
      line-height: 1.5rem;
      li {
        text-align: left;
      }
    }
  }
  .slick-slide:not(:last-child) {
    border-right: 0.0625rem solid #d8d8d8;
  }
  .slick-dots {
    button:before {
      background: #bebab7;
    }
    .slick-active button:before {
      background: #1577d9;
    }
  }
}

@media screen and (max-width: 768px) {
  .prysmSlider {
    .sliderItem {
      width: 100%;
      max-width: 20rem;
      margin: 0 auto 1.6rem auto;
      ul {
        margin-bottom: 2rem;
      }
      h4 {
        margin-bottom: 1.3rem;
      }
    }
    .slick-slide:not(:last-child) {
      border-right: none;
    }
  }
}
