@import '../../assets/scss/variable';

.prysmAccordion {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  color: #89817b;
  .accordionPanel {
    h5 {
      margin: 0.3rem 0 1.1rem 0;
    }
    p {
      line-height: 1.3;
      margin-bottom: 0.5rem;
    }
    .databaseList {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 48%;
        margin-bottom: 1rem;
        img {
          width: 1.4375rem;
          margin-right: 0.5rem;
        }
        .databaseDescription {
          span {
            display: block;
          }
        }
      }
    }
    button {
      margin-bottom: 0.6rem;
    }
    .vip {
      max-width: 26.1875rem;
      margin-left: 2rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .prysmAccordion {
    .accordionPanel {
      padding: 2rem;
    }
  }
}
