@import '../../assets/scss/variable';

.home {
  width: 100%;
  height: 100%;
  h1 {
    bottom: 14.7%;
    left: 6.8%;
  }
  .stay {
    display: flex;
    flex-wrap: wrap;
    margin: 3rem auto 1.4rem auto;
    justify-content: space-between;

    .stayLeft {
      width: 60%;
      padding-left: 5.4%;

      .stayLeftInner {
        padding-right: 2%;

        p:nth-last-child(1) {
          margin-bottom: 0.7rem;
        }
        .homeLogo {
          max-width: 100%;
          text-align: center;
          img {
            max-width: 44%;
            margin-bottom: 1rem;
          }
        }
      }
      h3 {
        margin-bottom: 1.7rem;
        letter-spacing: -0.01875rem;
      }
      .stayButton {
        width: 100%;
        text-align: right;
      }
    }
    .stayRight {
      width: 38%;
      .halfMonitor {
        //position: absolute;
        //width: 100%;
        right: 0;
      }
      .monitor {
        display: none;
      }
    }
  }
  .grateful {
    padding-top: 10em;
    text-align: center;
    margin-bottom: 8.25rem;
    max-width: 1400px;
    h3 {
      margin-bottom: 2.6rem;
    }
    img {
      height: 4rem;
      margin-bottom: 1.1rem;
    }
  }
  .why {
    max-width: 80%;
    margin: 0 auto;
    padding: 1.2rem 0 0 0;
    background-color: $lightColor;
    h3 {
      text-align: center;
      margin-bottom: 0.8rem;
    }
    h4 {
      text-align: center;
      color: $lightBrown;
      max-width: 28rem;
      margin: 0 auto;
    }
  }
  .technology {
    text-align: center;
    background-color: $lightColor;
    padding: 1.6rem 0;
    margin: 5rem 0 0 0;
    letter-spacing: -0.03rem;
    h3 {
      margin-bottom: 1rem;
    }
    h4 {
      max-width: 30rem;
      margin: 0 auto;
      color: $lightBrown;
    }
  }
  hr {
    border: none;
    background: $lightColor;
    height: 2.125rem;
  }
}

@media screen and (max-width: 768px) {
  .home {
    .stay {
      flex-direction: column;
      .stayLeft {
        width: 100%;
        padding: 0 2rem;
        margin-bottom: 2rem;
        button {
          display: none;
        }
        .stayLeftInner {
          background: $whiteColor;
          position: relative;
          margin-top: -6.2rem;
          padding: 25px 25px 0 25px;
        }
      }
      .stayRight {
        width: 68%;
        margin: 0 auto;
        .halfMonitor {
          display: none;
        }
        .monitor {
          display: block;
        }
      }
    }
    .why {
      max-width: 100%;
      background: $whiteColor;
      padding: 0;
      :global {
        .container {
          padding: 0;
        }
      }
    }
    hr {
      margin-bottom: 4rem;
    }
    h1 {
      bottom: 22%;
      left: 9.8%;
    }
    .grateful {
      margin-bottom: 3.9rem;
    }
    .technology {
      margin: 2rem 0 0 0;
      max-height: 14.375rem;
      > div {
        padding: 1rem;
        background: white;
        margin: 2rem 2rem 0 2rem;
        width: calc(100% - 4rem);
        h3 {
          margin-bottom: 2rem;
        }
        h4 {
          text-align: left;
          font-weight: normal;
        }
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  .home {
    h1 {
      left: 6.5rem;
    }
  }
}
