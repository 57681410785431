@import 'assets/scss/variable';
//@import url("https://use.typekit.net/zgc3xjf.css");

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Neuzeit Grotesk', sans-serif;
  color: $blackColor;
}

html {
  font-size: 16px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}

ul {
  list-style-type: none;
}

img {
  width: 100%;
}

h1 {
  font-weight: 100;
  font-size: 3.75rem;
  color: $whiteColor;
  letter-spacing: 0;
  line-height: 1;
}

h2 {
  font-weight: 100;
  font-size: 2.75rem;
  line-height: 3.75rem;
}

h3 {
  font-weight: 100;
  font-size: 2.3rem;
  line-height: 2.5rem;
}

h4 {
  font-size: 1.5rem;
  letter-spacing: -0.001rem;
  line-height: 1.8rem;
}

p {
  font-weight: 400;
  font-size: 1.45rem;
  color: $lightBrown;
  letter-spacing: 0.001875rem;
  line-height: 1.75rem;
  margin-bottom: 1.2rem;
}

#root {
  height: 100%;
}

.container {
  //max-width: 87.5rem;
  width: 100%;
  margin: 0 auto;
}

.italic {
  font-style: italic;
}

.text-center {
  text-align: center;
}

blockquote {
  font-size: 1.375rem;
  color: #7e7670;
  letter-spacing: -0.00625rem;
  line-height: 2.625rem;
  position: relative;
  margin: 0 auto 4rem auto;
  max-width: 43rem;
  padding: 0 3rem;
  &:after,
  &:before {
    content: '"';
    position: absolute;
    width: 2rem;
    height: 2rem;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 3.75rem;
    color: #233d8c;
    letter-spacing: -0.036875rem;
    text-align: center;
    line-height: 1.78125rem;
  }
  &:before {
    top: 0;
    left: 0;
  }
  &:after {
    bottom: 4rem;
    right: 0;
  }
  span {
    margin-top: 1rem;
    display: block;
    text-align: right;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
    bottom: 13%;
    left: 9%;
  }
  h3 {
    font-size: 1.125rem;
    letter-spacing: 0.001875rem;
    line-height: 1.625rem;
  }

  blockquote {
    margin: 0 auto 10rem auto;
    font-size: 0.875rem;

    color: #7e7670;
    letter-spacing: -0.00375rem;
    line-height: 1.1875rem;
    &:after {
      bottom: 1rem;
    }
  }
}
