@import '../../assets/scss/variable';

@media (any-hover: hover) {
  .header {
    .headerInner {
      .menu {
        ul li a:hover {
          color: $blueColor;
        }
      }
      .linkedin:hover {
        opacity: 0.8;
      }
    }
  }
}

.header {
  .headerInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 4.3rem;
    padding: 0 3.1%;
    .logo {
      width: 7.5rem;
    }
    .menu {
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
          a {
            text-decoration: none;
            font-size: 1rem;
            color: #4f4f4f;
            letter-spacing: 0.00625rem;
            text-align: center;
            padding: 2.12rem 4rem;
            display: block;
            &:active {
              color: $mainColor;
              opacity: 0.8;
            }
            &.active {
              color: $blueColor;
              font-weight: bold;
            }
          }
        }
      }
    }
    .linkedin {
      width: 1rem;
      &:active {
        opacity: 0.8;
      }
    }
  }
  .burgerMenu {
    display: none;
    position: fixed;
    z-index: 30;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    top: 0.1rem;
    right: 1.2rem;
    background-color: rgba(255, 255, 255, 0.5);
    transition: 0.4s;
    box-shadow: none;
    border: none;
    cursor: pointer;
    &:hover,
    &:active {
      opacity: 0.7;
    }
    .burgerMenuLines,
    .burgerMenuLines:after,
    .burgerMenuLines:before {
      position: absolute;
      width: 2rem;
      height: 0.1875rem;
      background-color: $blackColor;
      left: 0;
      transition: 0.4s;
    }
    .burgerMenuLines {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &:after {
        content: '';
        top: -0.625rem;
      }
      &:before {
        content: '';
        top: 0.625rem;
      }
    }
    &.active {
      .burgerMenuLines {
        background-color: transparent;
        &::before {
          top: 0;
          transform: rotate(-45deg);
        }
        &::after {
          top: 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}

.hide {
  display: none;
}

@media screen and (max-width: 567px) {
  .header {
    position: fixed;
    top: 0;
    z-index: 10;
    background: white;
    border-bottom: 1px solid #dbdbdb;
    .linkedin {
      display: none;
    }
    .headerInner {
      padding: 0 2rem;
      .menu {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 20;
        background: rgb(255, 250, 250);
        left: 0;
        ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          li {
            a {
              font-size: 2rem;
            }
          }
        }
      }
    }
    .burgerMenu {
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
  }
}

@media screen and (min-width: 567px) {
  .header {
    .headerInner {
      .menu {
        display: block;
      }
    }
  }
}
